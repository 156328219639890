<template>
    <div class="pageBox">
        <div class="topBox">
            <div class="titleBox">
                <img src="../../assets/siteImg/title.png">
                历史评估记录
            </div>
            <div class="tableBox">
                <div class="topBar">
                    <div><span>文物：</span>{{ relic.relicname }}</div>
                    <div><span>材质：</span>{{ relic.material }}</div>
                    <div><span>详情：</span>{{ relic.detail }}</div>
                    <div><span>病害：</span>{{ relic.disease }}</div>
                </div>
                <el-table :data="tableData" border style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="title" label="评估名称" />
                    <el-table-column prop="createtime" label="评估时间" />
                    <el-table-column prop="risk" label="风险描述" />
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <el-button size="small" type="primary" plain @click="lookMsg(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <detilesForm ref="detilesForm"/>
    </div>
</template>
<script>
import detilesForm from './modules/hisoper.vue'
import { site_listEval } from '@/api/site-warning.js'
export default{
    components:{
        detilesForm
    },
    data(){
        return{
            relic:{},
            relicId:null,
            tableData:[]
        }
    },
    mounted(){
        if(this.$route.query.relic){
            this.relicId = this.$route.query.relic
            this.getList()
        }
        
    },
    methods:{
        getList(){
            site_listEval(this.relicId).then(r=>{
                this.tableData = r.data.offlineevals
                this.relic = r.data.relic

            })
        },
        lookMsg(id){
            this.$refs.detilesForm.open(id)
        },
        goback(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba($color: #000000, $alpha: 0);
    .topBox{
        width:100%;
        min-height:calc(100vh - 100px);
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
    }
    .tableBox{
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .topBar{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span{
            color: #2540E2;
        }
    }
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}


</style>